
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class CmxAboutUs extends Vue {
  private page: string = 'Home';
  aboutContent: object = {};
  brandContent: object = {};

  // 获取关于我们cms内容
  getAboutContent () {
    this.$Api.cms
      .getContentByDevice({ key: 'about_us', IsMobile: this.isMobile })
      .then(result => {
        this.aboutContent = result.CMS;
      });
  }
  // 获取关于我们cms内容
  getBrandContent () {
    this.$Api.cms
      .getContentByDevice({ key: 'brand', IsMobile: this.isMobile })
      .then(result => {
        this.brandContent = result.CMS;
        if (result.CMS && result.CMS.Cover) {
          $('.blue-bg').css({ 'background': `url(${result.CMS.Cover}) no-repeat`, 'background-size': '100% 100%' });
        }
      });
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }

  get lang () {
    return this.$store.state.lang;
  }

  toUrl (url) {
    if (url) {
      window.location.href = url;
    }
  }

  mounted () {
    this.getAboutContent();
    this.getBrandContent();
  }

  @Watch('isMobile', { deep: true })
  onMediaChange () {
    this.getAboutContent();
    this.getBrandContent();
  }
}
